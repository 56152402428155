@right-width: 355px;
@right-height: 135px;

// TODO: create a class replacing all top ids below

#customer-deposit-accounts-div,
#customer-term-deposits-div,
#customer-loans-div,
#customer-pawns-div,
#customer-stock-items-div,
#customer-layaways-div,
#item-history-div,
  .products {

  .content {
    margin: 0 20px;

    .text-alert {
      border-color: #eed3d7;
      color: #b94a48;
      font-weight: bold;
    }

    .accounts-div {
      display: flex;
      margin-top: 10px;

      .right-details {
        margin-left: 5px;

        .tab-content {

          .tab-pane {
            width: @right-width;
            min-height: @right-height;
            padding: 0 10px;
          }

          #owners {
            a.link {
              cursor: pointer;
            }

            .signature {
              padding-left: 5px;
            }

            td {
              text-align: center;
            }

          }

          .details-pane {
            padding-top: 10px;
            .signature {
              padding-left: 5px;
            }

            td {
              text-align: center;
            }

          }

          .details-pane {
            padding-top: 10px;

            .detail-rows {

              .details-row {
                margin-bottom: 5px;

                a {
                  cursor: pointer;
                }

                .key {
                  font-weight: bold;
                  margin-right: 5px;
                }
                .value {
                  &[data-status=ACTIVE] {
                    color: green;
                    font-weight: bold;
                  }
                  &[data-status=CLOSED] {
                    color: red;
                    font-weight: bold;
                  }
                }
              }
            }
          }
        }
      }

      .table {
        .php-positive {
          color: green;
        }
        .php-negative {
          color: red;
        }

      }

    }

    .history {
      table {
        margin-bottom: 0;

        td.type-CREDIT,
        td.status-PROCESSED {
          color: green;
        }
        td.status-PENDING {
          color: orange;
        }

        td.type-DEBIT,
        td.status-REJECTED,
        td.status-ERROR {
          color: red;
        }
      }
    }

    .passbook {

      .passbook-bar {
        padding: 8px;
        font-weight: bold;
      }

      table {

        th input {
          margin-bottom: 0;
          width: 60px;
        }

        td.printed-true {
          color: green;
        }
        td.printed-false {
          color: orange;
        }
        td.text-center {
          text-align: center;
        }

      }
    }

    .customer-loans-amortization-buttons {
      align-items: stretch;
      display: flex;
      justify-content: flex-start;

      button {
        margin-right: 6px;
      }
    }

    .products-menu {
      margin-top: 20px;
      //border-top: solid 1px lightgray;
    }
  }
}

#operations {
  flex-wrap: wrap;
  padding-top: 10px;

  .message {
    text-align: center;
    margin-top: 35px;
    height: 100%;
    color: red;
  }

  .operation-row {
    flex-wrap: wrap;

    .btn-group+.btn-group {
      margin-left: 0;
    }

    button {
      margin: 2px 0 2px 2px;
      width: 80px;
      height: 60px;
      font-weight: bold;
      font-size: 13px;
      line-height: 120%;
      padding: 0;
    }
  }
}

