stock-item-transfer-to-head-office-view {
  .content {
    background-color: white;
    vertical-align: middle;
    padding:20px;
    display: flex;
    flex-wrap: wrap;

    .select-branch {
      padding-top: 10px;
      margin: unset;
    }

    .send-to-branch-bar {
      display: flex;

      .action-buttons {
        margin-left: auto;
        padding: 10px 10px 10px;
      }
    }
  }
}